import React from 'react'
import Slider from 'react-slick'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import ContentBlock from '../components/contentBlock'
import Header from '../components/header'

export default (props) => {
	const {data} = props;
  const {seoMetaTags } = data.page;

  return(
	<Layout seo={seoMetaTags} >
		<Header />
		<ContentBlock
			content={data.page}
			location={props.location}
			slug={props.pageContext.slug}
			title={'Jurisdiction'}
		/>
	</Layout>
  )
}

export const query = graphql`
	query JurisdictionQuery($slug: String!) {
		page: datoCmsJurisdiction(slug: { eq: $slug }) {
			seoMetaTags {
				...GatsbyDatoCmsSeoMetaTags
			}
			title
			bodyContent {
				... on DatoCmsHeading {
					id
					keyMessage
				}
				... on DatoCmsText {
					id
					sectionTitle
					text
				}
				... on DatoCmsIframe {
					id
					sectionTitle
					link
				}
				... on DatoCmsImage {
					sectionTitle
					id
					image {
						url
						format
						size
						fluid(maxWidth: 900, imgixParams: { fm: "jpg", auto: "compress" }) {
							...GatsbyDatoCmsSizes
						}
					}
				}
				... on DatoCmsTable {
					sectionTitle
					id
					table {
						file {
							csv
							url
							format
							size
						}
					}
				}
				... on DatoCmsHtmlChart {
					sectionTitle
					id
					htmlChart {
						description
						csvFile {
							id
							url
							format
							size
						}
						htmlFile {
							id
							url
							path
						}
					}
				}
				... on DatoCmsDatum {
					sectionTitle
					id
					chart {
						chartDescriptionNode {
							childMarkdownRemark {
								html
							}
						}
						chartType {
							title
						}
						file {
							csv
							url
							format
							size
						}
					}
				}
			}
		}
	}
`
